<template>
  <div class="author">
    <!-- :class="activePage == 'author' ? '' : 'hidden'">-->
    <h1>Über die Autorin</h1>
    <div class="image"></div>
    <div class="text">
      Nadja Schindler wurde 1989 in der Oberpfalz geboren. Sie studierte in
      Nürnberg und Stuttgart Grafikdesign, Illustration und visuelle
      Kommunikation. Bereits im Studium keimte die Idee für ihren Debütroman
      Nephilim - Spread your wings. Nach ihrem Masterabschluss lebte und
      arbeitete sie sechs Jahre in Berlin, bis es sie mit Kind und Kegel zurück
      in ihre alte Heimat zog. Dort vollendete und veröffentlichte sie ihr
      erstes Buch Nephilim - Spread your wings.
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "Author",
  props: {
    activePage: String,
  },
};
</script>

<style scoped>
.author {
}
.image {
  float: right;
  width: 320px;
  height: 320px;
  margin: 20px 40px 20px 0px;
  background-image: url(../../public/assets/autorin_pic.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 100%;
  border: 1px solid #009384;
}
.text {
  float: left;
  width: 50%;
  padding: 20px 0px 20px 40px;
  line-height: 1.4em;
}
.clear {
  clear: both;
}
@media screen and (max-device-width: 1040px) {
  .image {
    width: 100%;
    float: unset;
    padding: unset;
    border: unset;
    border-radius: unset;
    background-size: contain;
  }

  .text {
    float: unset;
    width: unset;
    padding: 20px;
  }
}

@media screen and (max-device-width: 500px) {
  .image {
    background-size: cover;
  }
}
</style>
