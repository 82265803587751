<template>
  <div class="content">
    <Title title="Nephilim" subtitle="spread your wings" />
    <!--<Navigation
      @changeActivePage="(page) => onPageChanged(page)"
      :activePage="activePage"
    />-->
    <TheBook :activePage="activePage" />
    <Citation />
    <Buy :activePage="activePage" />
    <Author :activePage="activePage" />
    <Footer />
  </div>
</template>

<script>
import Title from "./components/Title.vue";
import Footer from "./components/Footer.vue";
import Citation from "./components/Citation.vue";
import TheBook from "./pages/TheBook.vue";
import Buy from "./pages/Buy.vue";
import Author from "./pages/Author.vue";

export default {
  name: "App",
  components: {
    Title,
    TheBook,
    Buy,
    Author,
    Footer,
    Citation,
  },
  methods: {
    onPageChanged: function (page) {
      console.log("event: " + page + " old: " + this.activePage);
      this.activePage = page;
    },
  },
  data: function () {
    return {
      activePage: "thebook",
    };
  },
};
</script>

<style>
#app {
  width: 1040px;
  margin: 0 auto;
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
  background-color: #eee;
}

* {
  box-sizing: border-box;
  font-family: Lora;
}

h1 {
  text-align: center;
  font-family: Cookie;
  font-weight: normal;
  font-size: 4em;
  margin: 1.1em 0 0.2em;
  color: #009384;
}

.hidden {
  display: none;
}

@font-face {
  font-family: "Cookie";
  font-weight: 200;
  font-style: normal;
  font-display: auto;
  src: url("/assets/fonts/Cookie-Regular.ttf") format("woff2");
}

@font-face {
  font-family: "Lora";
  font-weight: 200;
  font-style: normal;
  font-display: auto;
  src: url("/assets/fonts/Lora-VariableFont_wght.ttf") format("woff2");
}

@media screen and (max-device-width: 1040px) {
  #app {
    width: 100%;
  }

  h1 {
    font-size: 3em;
  }
}
</style>
