<template>
  <div class="impressum">
    <div class="vcenter">
      Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
      Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch
      keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
      für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
      verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
      nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
      überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
      Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
      unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
      von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.<br />
      Website Impressum erstellt durch
      <a href="https://www.impressum-generator.de">impressum-generator.de</a>
      von der
      <a href="https://www.kanzlei-hasselbach.de/" rel="nofollow"
        >Kanzlei Hasselbach</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Impressum",
};
</script>

<style scoped>
.impressum {
  font-size: 1em;
  padding: 20px;
  text-align: center;
  color: white;
  position: relative;
  height: 100%;
}
.impressum a {
  color: white;
}
.impressum .vcenter {
  position: absolute;
  left: 50%;
  width: calc(100% - 40px);
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
