<template>
  <div class="footer">
    <Impressum />
  </div>
</template>

<script>
import Impressum from "./../pages/Impressum.vue";
export default {
  name: "Footer",
  components: {
    Impressum,
  },
};
</script>

<style scoped>
.footer {
  height: 350px;
  padding: 50px 50px 0;
  background-image: url(../../public/assets/footer.jpg);
}

@media screen and (max-device-width: 1040px) {
  .footer {
    padding: 40px 0 0 0;
    font-size: 0.7em;
  }
}
</style>
