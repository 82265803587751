<template>
  <div class="thebook">
    <!-- :class="activePage == 'author' ? '' : 'hidden'">-->
    <h1>Über das Buch</h1>
    <div class="image">
      <img :src="'./assets/pic_1_book.jpg'" />
    </div>
    <div class="text">
      Engel, Übermächtiges, Himmelsgeschehen – an all das glaubt Alisa absolut
      nicht. Erst recht nicht, seitdem ihre große Liebe bei einem Autounfall ums
      Leben kam. Doch plötzlich ist dieser Neue an ihrer Schule, der ihre
      Gefühlswelt ziemlich durcheinanderbringt.<br /><br />

      Simon gibt sich wirklich Mühe, endlich ein geordnetes Leben zu führen. Da
      taucht dieses blaue Licht wieder an ihm auf und erinnert ihn daran, dass
      er alles andere als normal ist.<br />
      Simons Welt gerät mehr und mehr ins Wanken: Wer ist dieser ausgeflippte
      Engel? Was wollen diese seltsamen Kreaturen von ihm? Und wie bekommt er
      seine chaotischen Gefühle wieder unter Kontrolle?<br /><br />

      Genre: Romantasy<br />
      Seitenzahl: 440<br />
      Sprache: Deutsch<br />
      ISBN:978-3-7557-9600-8
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "Content",
  components: {},
  props: {
    activePage: String,
  },
};
</script>

<style scoped>
.thebook {
  font-size: 1em;
}
.image {
  float: right;
  width: 490px;
  padding: 20px 40px 20px 0px;
}
.text {
  float: left;
  width: 50%;
  padding: 20px 0px 20px 40px;
  line-height: 1.4em;
}
.clear {
  clear: both;
}
img {
  height: 300px;
  width: 450px;
}

@media screen and (max-device-width: 1040px) {
  img {
    width: 100%;
    height: unset;
  }

  .image {
    width: 100%;
    float: unset;
    padding: unset;
  }

  .text {
    float: unset;
    width: unset;
    padding: 20px;
  }
}
</style>
