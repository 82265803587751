<template>
  <div class="header"></div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style scoped>
.header {
  height: 350px;
  padding: 20px;
  background-image: url(../../public/assets/header1.jpg);
}

@media screen and (max-device-width: 1040px) {
  .header {
    background-position: right;
    background-size: cover;
  }
}

@media screen and (max-device-width: 870px) {
  .header {
    background-image: url(../../public/assets/header_tablet.jpg);
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-device-width: 500px) {
  .header {
    background-image: url(../../public/assets/header_mobile.jpg);
    background-position: center;
    background-size: cover;
  }
}
</style>
