<template>
  <div class="citation">
    <div class="vcenter">
      "Er hatte Flügel! Richtige, echte Engelsflügel mit weißen Federn."
    </div>
  </div>
</template>

<script>
export default {
  name: "Citation",
};
</script>

<style scoped>
.citation {
  position: relative;
  padding: 0px 20px;
  text-align: center;
  color: white;
  height: 300px;
  font-style: italic;
  background-image: url(../../public/assets/zitat_background.jpg);
  background-position: center;
}

.citation .vcenter {
  font-size: 2.5em;
  font-family: Cookie;
  position: absolute;
  left: 50%;
  width: calc(100% - 40px);
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-device-width: 1040px) {
}
</style>
