<template>
  <div class="buy">
    <!-- :class="activePage == 'author' ? '' : 'hidden'">-->
    <h1>Erhältlich bei diesen Anbietern</h1>
    <a
      href="https://www.amazon.de/Nephilim-Spread-wings-Nadja-Schindler/dp/B09SNYBHLD"
      ><img :src="'./assets/logos/amazon.jpg'"
    /></a>
    <a href="https://www.thalia.de/shop/home/artikeldetails/A1063210648"
      ><img :src="'./assets/logos/thalia.jpg'"
    /></a>
    <a href="https://www.bod.de/buchshop/nephilim-nadja-schindler-9783755796008"
      ><img :src="'./assets/logos/bod.jpg'"
    /></a>
    <a
      href="https://www.hugendubel.de/de/taschenbuch/nadja_schindler-nephilim-42187215-produkt-details.html"
      ><img :src="'./assets/logos/hugendubel.jpg'"
    /></a>
  </div>
</template>

<script>
export default {
  name: "Buy",
  props: {
    activePage: String,
  },
};
</script>

<style scoped>
.buy {
  padding: 20px;
  text-align: center;
}

a {
  margin: 10px;
}
</style>
